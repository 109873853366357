import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useChickens } from "./ChickenProvider";

export default function Trivia({
  setShowCorrectAnswer,
  setShowIncorrectAnswer
}) {
  const { getChicken } = useChickens();

  const chicken = getChicken();

  return (
    <Box>
      <Typography variant="h2" textAlign="center" my={8}>
        Is Chicken?
      </Typography>
      <Box display="block">
        <img width="100%" src={chicken} />
      </Box>
      <Box display="flex" width="100%" justifyContent="space-around" mt={8}>
        <Box>
          <Button
            onClick={() => setShowCorrectAnswer(true)}
            size="large"
            variant="outlined"
            sx={(theme) => ({
              color: theme.palette.success.main,
              borderColor: theme.palette.success.main,
              "&:hover": {
                borderColor: theme.palette.success.main,
                backgroundColor: theme.palette.success.light,
                color: "white"
              }
            })}
          >
            hahahah Yes
          </Button>
        </Box>
        <Box>
          <Button
            size="large"
            variant="outlined"
            sx={(theme) => ({
              color: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              "&:hover": {
                borderColor: theme.palette.error.main,
                backgroundColor: theme.palette.error.light,
                color: "#fff"
              }
            })}
            onClick={() => setShowIncorrectAnswer(true)}
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
