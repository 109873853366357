import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import chickenError from "./chicken-error.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export default function InCorrectAnswer({ nextQuestion }) {
  return (
    <Box>
      <Typography
        variant="h2"
        textAlign="center"
        my={8}
        sx={(theme) => ({ color: theme.palette.error.main })}
      >
        No
      </Typography>
      <Box
        margin="auto"
        width="400px"
        height="400px"
        borderRadius="1000px"
        padding="85px"
        position="relative"
        sx={(theme) => ({
          border: `1px solid ${theme.palette.error.main}`
        })}
      >
        <HighlightOffIcon
          color="danger"
          sx={(theme) => ({
            position: "absolute",
            fontSize: "100px",
            top: 0,
            right: "74px",
            color: theme.palette.error.light
          })}
        />
        <img
          width="75%"
          style={{ margin: "auto", display: "block", marginTop: "50px" }}
          src={chickenError}
        />
        <Typography
          textAlign="center"
          sx={(theme) => ({ color: theme.palette.error.main })}
          variant="overline"
          fontWeight={700}
          fontSize={28}
          component="p"
        >
          Fuck you
        </Typography>
      </Box>
      <Button
        onClick={nextQuestion}
        variant="outlined"
        size="large"
        sx={(theme) => ({
          display: "block",
          margin: "auto",
          marginTop: "32px",
          fontWeight: 700,
          color: "black",
          borderColor: "black",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
            borderColor: "black"
          }
        })}
      >
        Next Chicken Techincal{" "}
        <ArrowForwardIosIcon sx={{ fontSize: "12px", marginLeft: "8px" }} />
      </Button>
    </Box>
  );
}
