import React, { useContext, useEffect, useState } from "react";
import flickrClient from "./flicker-client";

export const ChickenContext = React.createContext({ getChicken: () => {} });

export default function ChickenProvider({ children }) {
  const [chickens, setChickens] = useState([]);

  useEffect(() => {
    flickrClient.photos.search({ text: "chicken" }).then((response) => {
      console.log(response.body.photos);

      setChickens(response.body.photos.photo);
    });
  }, [flickrClient]);

  const getChicken = () => {
    if (!chickens?.length) return;

    const chicken = chickens.find(() => Math.random() < 0.02);

    if (!chicken) return getChicken();

    const { server, id, secret } = chicken;

    const url = `https://live.staticflickr.com/${server}/${id}_${secret}_c.jpg`;

    return url;
  };

  return (
    <ChickenContext.Provider value={{ getChicken }}>
      {children}
    </ChickenContext.Provider>
  );
}

export const useChickens = () => {
  return useContext(ChickenContext);
};
