import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  ThemeProvider,
  createTheme
} from "@mui/material";
import { createRoot } from "react-dom/client";
import Trivia from "./Trivia";
import CorrectAnswer from "./CorrectAnswer";
import InCorrectAnswer from "./IncorrectAnswer";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import ChickenProvider from "./ChickenProvider";
import flickrClient from "./flicker-client";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {},
  typography: {
    button: {
      fontWeight: 700
    }
  }
});

const Default = () => {
  return (
    <Typography variant="h1" mt={8} textAlign="center">
      Coming soon
    </Typography>
  );
};

const Main = () => {
  const [view, setView] = useState("trivia");
  const [showQuestion, setShowQuestion] = useState(true);
  const [score, setScore] = useState([0, 0]);

  useEffect(() => {
    const [correctAnswers, totalQuestions] = score;

    if (view === "incorrectAnswer") {
      setScore([correctAnswers, totalQuestions + 1]);
    } else if (view === "correctAnswer") {
      setScore([correctAnswers + 1, totalQuestions + 1]);
    }
  }, [view]);

  const Content = {
    trivia: (
      <Trivia
        setShowCorrectAnswer={() => setView("correctAnswer")}
        setShowIncorrectAnswer={() => setView("incorrectAnswer")}
      />
    ),
    correctAnswer: <CorrectAnswer nextQuestion={() => setView("trivia")} />,
    incorrectAnswer: <InCorrectAnswer nextQuestion={() => setView("trivia")} />
  }[view] || <Default />;

  const nav = (() => {
    if (["incorrectAnswer", "correctAnswer", "trivia"].includes(view)) {
      return "trivia";
    }

    return view;
  })();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ChickenProvider>
          <Box>
            <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="baseline"
            >
              <Box
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.success.main}`,
                  paddingLeft: "15px"
                })}
              >
                <Typography
                  fontWeight={700}
                  variant="overline"
                  fontSize={24}
                  letterSpacing={15}
                >
                  {score[0]}/{score[1]}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="right">
                <ToggleButtonGroup
                  color="primary"
                  value={nav}
                  exclusive
                  onChange={(e: any) => setView(e.target.value)}
                >
                  <ToggleButton value="trivia">Trivia</ToggleButton>
                  <ToggleButton value="inifinite">
                    Infinite Chickens
                  </ToggleButton>
                  <ToggleButton value="history">History</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <Box width="800px" margin="auto">
              {Content}
            </Box>
          </Box>
        </ChickenProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const root = createRoot(document.getElementById("#target"));

root.render(<Main />);
