import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import chickenSuccess from "./chicken-success.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function CorrectAnswer({ nextQuestion }) {
  return (
    <Box>
      <Typography
        variant="h2"
        textAlign="center"
        my={8}
        sx={(theme) => ({ color: theme.palette.success.main })}
      >
        Yes
      </Typography>
      <Box
        margin="auto"
        width="400px"
        borderRadius="1000px"
        border="1px solid green"
        padding="85px"
        position="relative"
      >
        <CheckCircle
          color="success"
          sx={{
            position: "absolute",
            fontSize: "100px",
            top: 0,
            right: "74px"
          }}
        />
        <img width="100%" src={chickenSuccess} />
        <Typography
          textAlign="center"
          sx={(theme) => ({ color: theme.palette.success.main })}
          variant="overline"
          fontWeight={700}
          fontSize={20}
          component="p"
        >
          Ya thats a fuckin chicken lol
        </Typography>
      </Box>
      <Button
        onClick={nextQuestion}
        variant="outlined"
        size="large"
        sx={(theme) => ({
          display: "block",
          margin: "auto",
          marginTop: "32px",
          fontWeight: 700,
          color: "black",
          borderColor: "black",
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
            borderColor: "black"
          }
        })}
      >
        Next Chicken Techincal{" "}
        <ArrowForwardIosIcon sx={{ fontSize: "12px", marginLeft: "8px" }} />
      </Button>
    </Box>
  );
}
